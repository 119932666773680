<template>
  <div>
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-form
      ref="formSenha"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        v-if="!loading"
        :loading="loading"
      >
        <crud-card
          class="mb-3"
          title="Alterar Senha"
        >
          <crud-password
            label-senha="Nova Senha"
            with-current
            with-confirmation
            class="pt-0 mt-0"
            :input-outlined="true"
          />
        </crud-card>
        <v-row
          justify="center"
          justify-sm="end"
          class="my-0"
        >
          <v-col cols="auto">
            <v-btn
              class="mr-3"
              color="error"
              rounded
              @click="$router.push({ path: `/${$user.get().role}` })"
            >
              <v-icon
                dark
                left
              >
                mdi-minus-circle
              </v-icon>
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="mr-3"
              color="success"
              rounded
              @click="updpassword"
            >
              <v-icon
                dark
                left
              >
                mdi-checkbox-marked-circle
              </v-icon>
              Atualizar senha
            </v-btn>
          </v-col>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>;
<script>
  import { mapFields } from 'vuex-map-fields'
  import { mapState, mapActions } from 'vuex'
  import rules from '../../utils/formRules'
  import UserProfileStore, { BOOTSTRAP_PROFILE, UPDPASSWORD } from '@/store/modules/userProfile'
  export default {
    data () {
      return {
        valid: false,
        rules,
        show: false,
        optionalFields: [],
        editAvatar: false,
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('userProfile', ['telefones', 'loading', 'isEditing']),
      ...mapFields('form', ['data.avatar', 'data.endereco']),
    },
    created () {
      if (!this.$store.state.userProfile) { this.$store.registerModule(['userProfile'], UserProfileStore) }
      this.BOOTSTRAP_PROFILE()
    },
    methods: {
      ...mapActions('userProfile', [BOOTSTRAP_PROFILE, UPDPASSWORD]),
      updpassword () {
        if (!this.$refs.formSenha.validate()) {
          this.snackbar = {
            show: true,
            message: 'Os campos de senha não correspondem. Por favor, corrija-os!',
          }
          return null
        }
        this.UPDPASSWORD({ user: this.$user, router: this.$router })
      },
    },
  }
</script>
